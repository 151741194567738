const Theme = {
  colors: {
    primary: '#F76E00',
    ultraLightOrange: '#FFEAD9',
    lightOrange: '#FFA500',
    white: '#FFFFFF',
    black: '#000000',
    pink: '#F6258A',
    blue: '#0DAABC',
    purple: '#322876',
    purpleUI: '#403780',
    yellow: '#FFD325',
    gray: '#AAAAAA',
    darkGray: '#888888',
    red: '#FA461C',
  },
  fonts: {
    dobRegular: '\'Dob-Regular\', sans-serif',
    wwsRegular: '\'Wws-Regular\', sans-serif',
    manropeBold: '\'Manrope-Bold\', sans-serif',
    manropeLight: '\'Manrope-Light\', sans-serif',
    manropeMedium: '\'Manrope-Medium\', sans-serif',
    manropeRegular: '\'Manrope-Regular\', sans-serif',
    manropeSemibold: '\'Manrope-Semibold\', sans-serif',
    manropeThin: '\'Manrope-Thin\', sans-serif',
  },
};

export default Theme;
