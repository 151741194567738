/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Container, BackgroundContainer, Strudel, Planet, StrudelContainer, Text
  , ButtonWebBrowser, ButtonSteam, ButtonSteamPhone, PhoneContainer, PhoneLogo, Logo, Video
} from './styles';
import { Box } from '@mui/material';
import Iframe from 'react-iframe'

function WWS(this: any): React.ReactNode {
  useEffect(() => {
    document.title = "wild wild space";

    return () => {
      document.title = "rebel rabbit";
    };
  }, []);

  const [searchParams] = useSearchParams();
  const show = searchParams.get('show');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [aspectRatio, setAspectRatio] = useState(screenWidth / screenHeight);
  const [counter, setCounter] = useState(0);
  const [strudelPosition, setStrudelPosition] = useState(0);
  const [planetPosition, setPlanetPosition] = useState(0);
  const [over, setOver] = useState(false);

  var s = 100;
  var p = 0;
  var time = 0;

  var planetSize = 432 / 1080 * 100;
  var videoSize = 960 / 1080 * 100;
  var strudelSize = 2400 / 1080 * 100;
  var steamHeight = 134 / 1080 * 100;
  var steamWidth = 489 / 1920 * 100;

  let flashingText = '';
  if (show === 'demo') {
    flashingText = 'WISHLIST OUR GAME';
  } else {
    flashingText = 'PLAY THE FREE DEMO!';
  }

  let buttonText = 'Open in Steam Client';

  let linkText = 'or open in web browser'

  useEffect(() => {
    const update = setInterval(() => {

      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      setAspectRatio(window.innerWidth / window.innerHeight);

      if (time >= 0.25) {
        s = 50;
        p = 25;
      }
      else {
        s = 50 * (time / 0.25);
        p = 25 * (time / 0.25);
      }

      setStrudelPosition(s);
      setPlanetPosition(p);
      setCounter(Math.round(time * 60 / 15));

      time += 1 / 60;

    }, 16.6666666667);
    return () => clearInterval(update);
  }, []);

  return (
    <Container width={screenWidth} height={screenHeight}>
      <BackgroundContainer width={screenWidth} height={screenHeight}>
        <img src="images/bg.png" alt="planet" />
      </BackgroundContainer>

      {(aspectRatio > 1.5)
        ? (
          <>
            <Planet size={planetSize} width={screenWidth} posX={planetPosition}>
              <img src="images/planet.png" alt="planet" />
            </Planet>

            <Video height={videoSize} width={screenWidth} posX={planetPosition}>
              <Iframe url="https://www.youtube.com/embed/FnojM9y3-Jk?si=MU5WOAt-rfDEqGao"
                width="100%"
                height="100%"
                frameBorder={0}
                id=""
                className=""
                display="block"
                position="relative" />
            </Video>

            <Strudel size={strudelSize} posX={strudelPosition}>
              <img src="images/strudel.png" alt="strudel" />

              <StrudelContainer size={screenHeight * 680 / 1080}>
                <Box display="flex-column">
                  <Logo height={screenHeight * 480 / 1080}>
                    <img src="images/logoWWS.png" alt="strudel" />
                  </Logo>

                  <Box height={screenHeight * 360 / 1080}>
                    <Text styles={counter % 2 === 0 ? "title" : "titleB"} fontSize={(screenHeight * 64 / 1080).toString() + "px"}>
                      {flashingText}
                    </Text>

                    <Box display="flex" margin="auto" alignItems="center" justifyContent="center">
                      <ButtonSteam href='steam://store/2341430' height={steamHeight}>

                        <img
                          src={over ? "images/button-selected.png" : "images/button-unselected.png"}
                          alt="steam"
                          onMouseOver={() => setOver(true)}
                          onMouseOut={() => setOver(false)} />

                        <Text styles="steam" fontSize={(screenHeight * 42 / 1080).toString() + "px"}>
                          {buttonText}
                        </Text>
                      </ButtonSteam>
                    </Box>

                    <Box display="flex" margin="auto" alignItems="center" justifyContent="center">
                      <ButtonWebBrowser href='https://store.steampowered.com/app/2341430/Wild_Wild_Space/' target="_blank" rel="noreferrer">
                        <Text styles="webbrowser" fontSize={(screenHeight * 36 / 1080).toString() + "px"}>
                          {linkText}
                        </Text>
                      </ButtonWebBrowser>
                    </Box>
                  </Box>
                </Box>
              </StrudelContainer>
            </Strudel>
          </>
        )
        : (
          <>
            <PhoneContainer>
              <Box width="100%" display="flex" margin="auto" alignItems="center" justifyContent="center">
                <PhoneLogo width={screenWidth * 1200 / 1920}>
                  <img src="images/logoWWS.png" alt="strudelPhone" />
                </PhoneLogo>
              </Box>

              <Text styles={counter % 2 === 0 ? "title" : "titleB"} fontSize={(screenWidth * 126 / 1920).toString() + "px"}>
                {flashingText}
              </Text>

              <Box display="flex" margin="auto" alignItems="center" justifyContent="center">
                <ButtonSteamPhone href='steam://store/2341430' width={steamWidth * 1.6}>
                  <img
                    src={over ? "images/button-selected.png" : "images/button-unselected.png"}
                    alt="steamPhone"
                    onMouseOver={() => setOver(true)}
                    onMouseOut={() => setOver(false)} />
                  <Text styles="steam" fontSize={(screenWidth * 75 / 1920).toString() + "px"}>
                    {buttonText}
                  </Text>
                </ButtonSteamPhone>
              </Box>

              <Box display="flex" margin="auto" alignItems="center" justifyContent="center">
                <ButtonWebBrowser href='https://store.steampowered.com/app/2341430/Wild_Wild_Space/'>
                  <Text styles="webbrowser" fontSize={(screenWidth * 72 / 1920).toString() + "px"}>
                    {linkText}
                  </Text>
                </ButtonWebBrowser>
              </Box>
            </PhoneContainer>
          </>
        )}

    </Container>
  );
}

export default WWS;
