import styled from 'styled-components';
import TextGlobal from '../../components/Text';
import { variant } from 'styled-system';

export const Text = styled(TextGlobal)`
   ${variant({
  prop: 'styles',
  variants: {
    name: {
      fontFamily: 'dobRegular',
      fontSize: '38px',
      color: 'white',
      textAlign: 'center',
      marginTop: '64px',
      marginBottom: '12px',
    },
    name2: {
      fontFamily: 'dobRegular',
      fontSize: '38px',
      color: 'white',
      textAlign: 'center',
      marginTop: '0px',
      marginBottom: '12px',
    },
    job: {
      fontFamily: 'dobRegular',
      fontSize: '28px',
      color: 'lightOrange',
      textAlign: 'center',
      marginTop: '0px',
    },
  },
})}
`;

export const ImgContainer = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0px;
  
  img
  {
    width: 100%;
    height: auto;
  }
`;