import styled from 'styled-components';

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img
  {
    width: 100%;
    height: auto;
  }
`;