import { SpaceProps } from 'styled-system';
import { ImgContainer, Text } from './styles';
import { Box } from '@mui/material';
import React from 'react';

type Props = {
  image: string;
  name: string;
  job: string;
  action?: () => void;
} & SpaceProps;

function CardEmployee({
  image,
  name,
  job,
  action,
  ...restProps
}: Props) {
  return (
    <Box>
      <ImgContainer {...restProps}>
        <img src={image} alt={image} />
      </ImgContainer>
      <Text styles={name === 'Rudy' || name === 'Yussef' ? 'name' : 'name2'}>
        {name === 'Rudy2' ? 'Rudy' : name === 'Yussef2' ? 'Yussef' : name}
      </Text>
      <Text styles='job'>
        {job}
      </Text>
    </Box>
  );
}

export default CardEmployee;
