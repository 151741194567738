import styled, { css } from 'styled-components';
import TextGlobal from '../../components/Text';
import { variant } from 'styled-system';

export const Text = styled(TextGlobal)`
   ${variant({
  prop: 'styles',
  variants: {
    title: {
      fontFamily: 'dobRegular',
      fontSize: '50px',
      color: 'white',
      textAlign: 'center',
    },
    desc: {
      fontFamily: 'dobRegular',
      fontSize: '20px',
      color: 'lightOrange',
      textAlign: 'center',
    },
    footer: {
      fontFamily: 'dobRegular',
      fontSize: '20px',
      color: 'gray',
      textAlign: 'center',
    },
  },
})}
`;

export const ImgContainer = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decorations: none;
  cursor: pointer;
  margin: 5px 20px;
`;

export const Line = styled.div`
  margin: 60px 10% 60px 10%;
  width: 80%;
  height: 2px;
  border-bottom: 1px solid black;
  background-color: ${(props) => props.theme.colors.primary};
`;

export const Container = styled.main<{ phone?: boolean }>`
  
   ${(props) => !props.phone && css`
    margin: 104px 74px 41px 74px;
   `}

   ${(props) => props.phone && css`
    margin: 16px 16px 32px 16px;
   `}
`;

export const Card = styled.main<{ phone?: boolean }>`
  
   ${(props) => !props.phone && css`
    background-color: white;
    border-radius: 16px;
    border: 1px solid #DBDFE3;

    padding: 78px 64px 32px 84px;
   `}

   ${(props) => props.phone && css`
      svg{
        transform: scale(0.4386);
      }
   `}
`;

export const NLines = styled.div`
  max-width: 780px!important;
  margin: auto;
`;