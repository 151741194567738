/* eslint-disable max-len */
//import Topbar from 'components/Topbar';
import { Container } from './styles';
import React from 'react';

type Props = {
  children: React.ReactNode;
  desktopView?: boolean;
};

function Layout({
  children,
  desktopView,
}: Props): JSX.Element {
  return (
    <Container>
      {children}
    </Container>
  );
}

export default Layout;
