/* eslint-disable import/prefer-default-export */
import styled, { keyframes, css } from 'styled-components';
import {
  variant, space, SpaceProps, layout, LayoutProps,
} from 'styled-system';

export type StyledProps =
  SpaceProps &
  LayoutProps;

type ButtonProps = {
  fullWidth?: boolean;
  loading?: string;
} & StyledProps;

const spinnerAnimation = keyframes`
  0% {
    -webkit-transform: translate(-50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const StyledButton = styled.button<ButtonProps>`
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 48px;
  border-radius: 48px;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  background-color: ${(props) => props.theme.colors.primary};
  position: relative;
  transition: all 200ms ease;
  cursor: pointer;
  border-width: 3px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.bold};
  
  svg{
    pointer-events:none;
  }

  ${(props) => props.fullWidth && css`
    flex: 100%;
    width: 100%;
  `}

  ${(props) => props.loading === 'true' && css`
    opacity: 0.9;
    overflow: hidden;
    pointer-events: none;

    ::before {
      content: '';
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      border: 3px solid #FFFFFF;
      border-left-color: transparent;
      animation-name: ${spinnerAnimation};
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    span {
      text-indent: -9999px;
    }
  `}

  ${() => variant({
  variants: {
    primary: {
      color: 'black',
      borderColor: 'primary',
      backgroundColor: 'primary',

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: 'white',
        },
      },
    },
    inverse: {
      color: 'primary',
      borderColor: 'primary',
      backgroundColor: 'black',

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: 'white',
          borderColor: 'white',
        },
      },
      '&:disabled': {
        color: 'white',
        borderColor: 'white',
        pointerEvents: 'none',
      },
    },
    white: {
      color: 'black',
      backgroundColor: 'white',
      borderColor: 'primary',
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: 'white',
          borderColor: 'primary',
          backgroundColor: 'primary',
          'svg': {
            opacity: '0.8!important',
            transition: 'all 200ms ease',
          },
        },
      },
      '&:disabled': {
        color: 'black',
        borderColor: 'primary',
        backgroundColor: 'primary',
        pointerEvents: 'none',
      },
    },
    white2: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "6px",
      paddingBottom: "6px",
      minHeight: "36px",
      borderRadius: "36px",
      color: 'black',
      backgroundColor: 'white',
      borderColor: 'primary',
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: 'primary',
          borderColor: 'primary',
          backgroundColor: 'black',
        },
      },
      '&:disabled': {
        color: 'primary',
        borderColor: 'primary',
        backgroundColor: 'black',
        pointerEvents: 'none',
      },
    },
  },
})}

  ${space};
  ${layout};
`;
