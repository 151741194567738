import styled from 'styled-components';
import {
   space, SpaceProps, layout, LayoutProps, typography, TypographyProps,
   flexbox, FlexboxProps,
} from 'styled-system';

type Props =
   SpaceProps
   & LayoutProps
   & TypographyProps
   & FlexboxProps;

const Box = styled.div<Props>`
  ${space};
  ${layout};
  ${typography};
  ${flexbox};
`;

export default Box;
