import React from 'react';
import SVG from 'react-inlinesvg';
import { StyledButton, StyledProps } from './styles';

type DefaultProps = {
  color?: string;
  variant?: 'primary' | 'inverse' | 'danger' | 'dangerInverse';
  icon: string;
  onClick?: any;
} & StyledProps;

const Button: React.FC<DefaultProps> = ({
  icon,
  color,
  onClick,
  ...restProps
}) => (
  <StyledButton {...restProps} onClick={onClick}>
    <SVG
      src={icon}
      width="40px"
      height="40px"
      title="src"
      fill={color}
    />
  </StyledButton>
);

export default Button;