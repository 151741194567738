import { useEffect, useState } from "react";

export function useIsVisible(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const currentRef = ref.current; // Copia ref.current a una variable local

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting && entry.intersectionRatio > 0);
            },
            {
                rootMargin: "-90px 0px 0px 0px"
            }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref]);

    return isIntersecting;
}