import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

import {
  Container, LogoContainer, TopContainer, Separator, Link, Text,
  SidebarContainer, SidebarContent, BlackBox, TopbarContainer,
} from './styles';
import { themeRR } from '../../../styles/theme';
import useMediaQuery from '@mui/material/useMediaQuery';

import IconButton from '../../IconButton';

interface TopbarProps {
  isJuegosVisible: boolean;
  isServiciosVisible: boolean;
  isNosotrosVisible: boolean;
  isPortafolioVisible: boolean;
  isClientesVisible: boolean;
  isContactoVisible: boolean;
  width: number;
}

function Topbar(props: TopbarProps): JSX.Element {
  const sm = useMediaQuery('(max-width: 700px)');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const topbarHeight = sm ? 60 : 80;

  function scrollToSection(sectionId: string, offset: number = 0) {
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetTop = section.offsetTop;
      const currentScrollTop = window.scrollY;
      if (offsetTop > currentScrollTop) {
        const auxOffset = sm ? 0 : offset;
        window.scrollTo({ top: offsetTop - auxOffset, behavior: 'smooth' });
      } else if (offsetTop < currentScrollTop) {
        window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
      } else {
      }
    }

    if (isOpen) setIsOpen(false);
  }

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState<"down" | "up" | null>(null);

    useEffect(() => {
      let lastScrollY = window.scrollY;

      setIsOpen(false);

      const updateScrollDirection = () => {
        const scrollY = window.scrollY;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection);
      return () => {
        window.removeEventListener("scroll", updateScrollDirection);
      }
    }, [scrollDirection]);

    return scrollDirection;
  };

  const scrollDir = useScrollDirection();

  return (
    <Container height={topbarHeight} className={scrollDir === "down" ? "hide" : "show"}>
      <TopbarContainer width={props.width}>
        <LogoContainer>
          <SVG
            src="images/icons/REBELRABBIT.svg"
            width="90%"
            height="90%"
            title="Logo"
            fill={themeRR.colors.primary}
          />
        </LogoContainer>

        {!sm ?
          (
            <TopContainer>
              <Link onClick={() => scrollToSection('Juegos', topbarHeight)}>
                <Text styles={props.isJuegosVisible ? "linkSelected" : "link"}>Juegos</Text>
              </Link>
              <Separator />
              <Link onClick={() => scrollToSection('Servicios', topbarHeight)}>
                <Text styles={props.isServiciosVisible ? "linkSelected" : "link"}>Servicios</Text>
              </Link>
              <Separator />
              <Link onClick={() => scrollToSection('Nosotros', topbarHeight)}>
                <Text styles={props.isNosotrosVisible ? "linkSelected" : "link"}>Nosotros</Text>
              </Link>
              <Separator />
              <Link onClick={() => scrollToSection('Portafolio', topbarHeight)}>
                <Text styles={props.isPortafolioVisible ? "linkSelected" : "link"}>Portafolio</Text>
              </Link>
              <Separator />
              <Link onClick={() => scrollToSection('Clientes', topbarHeight)}>
                <Text styles={props.isClientesVisible ? "linkSelected" : "link"}>Partners & Clientes</Text>
              </Link>
              <Separator />
              <Link onClick={() => scrollToSection('Contacto', topbarHeight)}>
                <Text styles={props.isContactoVisible ? "linkSelected" : "link"}>Contacto</Text>
              </Link>
            </TopContainer>
          ) :
          (
            <>
              <IconButton
                icon="images/icons/HAMBURGUESA.svg"
                color='#F76E00'
                onClick={() => setIsOpen(!isOpen)}
              />

              <SidebarContainer height={topbarHeight} isOpen={isOpen}>
                <SidebarContent>

                  <Link onClick={() => scrollToSection('Juegos', topbarHeight)}>
                    <Text styles={props.isJuegosVisible ? "linkSelected" : "link"}>Juegos</Text>
                  </Link>

                  <Link onClick={() => scrollToSection('Servicios', topbarHeight)}>
                    <Text styles={props.isServiciosVisible ? "linkSelected" : "link"}>Servicios</Text>
                  </Link>

                  <Link onClick={() => scrollToSection('Nosotros', topbarHeight)}>
                    <Text styles={props.isNosotrosVisible ? "linkSelected" : "link"}>Nosotros</Text>
                  </Link>

                  <Link onClick={() => scrollToSection('Portafolio', topbarHeight)}>
                    <Text styles={props.isPortafolioVisible ? "linkSelected" : "link"}>Portafolio</Text>
                  </Link>

                  <Link onClick={() => scrollToSection('Clientes', topbarHeight)}>
                    <Text styles={props.isClientesVisible ? "linkSelected" : "link"}>Partners & Clientes</Text>
                  </Link>

                  <Link onClick={() => scrollToSection('Contacto', topbarHeight)}>
                    <Text styles={props.isContactoVisible ? "linkSelected" : "link"}>Contacto</Text>
                  </Link>

                </SidebarContent>
              </SidebarContainer>

              {isOpen && (<BlackBox height={topbarHeight} onClick={() => setIsOpen(false)} />)}
            </>
          )
        }
      </TopbarContainer>
    </Container>
  );
}

export default Topbar;
