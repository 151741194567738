/* eslint-disable max-len */
//import Topbar from 'components/Topbar';
import { Logos, LogosSlide } from './styles';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

function ImgCarousel({
  children,
}: Props): JSX.Element {
  return (
    <Logos>
      <LogosSlide>
        {children}
      </LogosSlide>
      <LogosSlide>
        {children}
      </LogosSlide>
    </Logos>
  );
}

export default ImgCarousel;