import React from "react";
import { Text, Container, Return } from './styles'
import { Box } from "@mui/material";

function NotFound() {
  return (
    <Container>
      <Box width="500px" height="250px">
        <Text styles="title">ERROR 404</Text>
        <Text styles="desc">page not found</Text>

        <Return href="/">
          <Text styles="return">return to home</Text>
        </Return>
      </Box>
    </Container>
  );
}

export default NotFound;