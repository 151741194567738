import styled from 'styled-components';
import TextGlobal from '../../components/Text';
import { variant } from 'styled-system';

export const Text = styled(TextGlobal)`
   ${variant({
    prop: 'styles',
    variants: {
        title: {
            fontFamily: 'dobRegular',
            fontSize: '50px',
            color: 'white',
            textAlign: 'center',
            marginBottom: '0px',
            paddingBottom: '0px',
        },
        desc: {
            fontFamily: 'dobRegular',
            fontSize: '20px',
            color: 'gray',
            textAlign: 'center',
        },
        return: {
            fontFamily: 'dobRegular',
            fontSize: '24px',
            color: 'lightOrange',
            textAlign: 'center',
            paddingTop: '20px',
        },
    },
})}
`;

export const Container = styled.div`
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
          transform: translate(-50%, -40%);
  position: absolute;
  text-align: center;
`;

export const Return = styled.a`
  text-decoration: none;
  cursor: pointer;
`;