import styled, { keyframes } from 'styled-components';
import TextGlobal from '../../../components/Text';
import { variant } from 'styled-system';

export const Container = styled.div<{ width?: number, height?: number }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
`;

const rotate = keyframes`
  to {
    transform: rotate(-360deg);
  }
`;

export const Planet = styled.div<{ size?: number, width?: number, posX?: number }>`
  position: absolute;
  display: flex;
  left: calc(50vw - ${(props) => props.size}vh/2 + ${(props) => props.posX}vw);
  top: calc(50vh - ${(props) => props.size}vh/2);

  img{
    height: ${(props) => props.size}vh;
    width: ${(props) => props.size}vh;
    animation: ${rotate} 4s linear infinite;
  }
`;

export const Video = styled.div<{ height?: number, width?: number, posX?: number }>`
  position: absolute;
  width: 50vw;
  height: calc(1080/1920 * 50vw);
  right: 0px;
  top: calc(50vh - 1080/1920 * 50vw / 2);
  padding: 0px;
  margin: 0px;
  border: 0px;

  iframe {
    position: absolute;
    top: 0; bottom: 0; left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const PhoneContainer = styled.div`
  position: absolute;
  display: flex-column;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export const PhoneLogo = styled.div<{ width?: number }>`
  img
  {
    width: ${(props) => props.width}px;
  }
`;

export const Logo = styled.div<{ height?: number }>`
  img
  {
    height: ${(props) => props.height}px!important;
  }
`;

//-----------------------------------------------------------------------------------------

export const Strudel = styled.div<{ size?: number, posX?: number }>`
  position: absolute;
  right: calc(100% - ${(props) => props.posX}%);
  bottom: 0%;
  display: flex;

  img{
    height: ${(props) => props.size}vh;
  }
`;

export const StrudelContainer = styled.div<{ size?: number }>`
  position: absolute;
  display: flex;

  right: calc(25vw - ${(props) => props.size}px/3);
  bottom: calc(${(props) => props.size}px/6);
`;

export const BackgroundContainer = styled.div<{ width?: number, height?: number }>`
  position: absolute;
  display: flex;
  margin: 0px;
  padding: 0px;
  right: 0;

  img{
    height: 100vh;
  }
`;

export const Text = styled(TextGlobal)`
   ${variant({
  prop: 'styles',
  variants: {
    title: {
      fontFamily: 'wwsRegular',
      color: 'pink',
      textAlign: 'center',
    },
    titleB: {
      fontFamily: 'wwsRegular',
      color: 'purple',
      textAlign: 'center',
    },
    webbrowser: {
      fontFamily: 'wwsRegular',
      color: 'purpleUI',
      textAlign: 'center',
      textDecoration: 'underline',
      padding: '20px',
      margin: '0px',
      '&:hover': {
        color: "white",
      },
    },
    steam: {
      fontFamily: 'wwsRegular',
      color: 'white',
      textAlign: 'center',
      textDecoration: 'none',
      position: 'absolute',
      pointerEvents: 'none',
    },
  },
})}
`;

export const ButtonWebBrowser = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0);
  border: none;
  
  cursor: pointer;

  margin: 0px;
  padding: 0px;
`;

export const ButtonSteam = styled.a<{ height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  img
  {
    height: ${(props) => props.height}vh;
  }

  border: none;
  text-decoration: none;
  cursor: pointer;

  margin: 0px;
  padding: 0px;
`;

export const ButtonSteamPhone = styled.a<{ width?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  img
  {
    height: auto;
    width: ${(props) => props.width}vw;
  }

  border: none;
  text-decoration: none;
  cursor: pointer;

  margin: 0px;
  padding: 0px;
`;