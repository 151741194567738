import React from 'react';
import SVG from 'react-inlinesvg';
import { StyledButton, StyledProps } from './styles';
import { Box } from '@mui/material';

type DefaultProps = {
  children: React.ReactNode;
  variant?: 'primary' | 'inverse' | 'white' | 'white2';
  type?: 'button' | 'submit',
  disabled?: boolean;
  styles?: string;
  fullWidth?: boolean;
  loading?: boolean;
  icon?: string;
  color?: string,
  onClick?: any;
} & StyledProps;

const Button: React.FC<DefaultProps> = ({
  children,
  color,
  type = 'button',
  loading,
  icon,
  disabled = false,
  ...restProps
}) => (
  <StyledButton type={type} loading={`${loading}`} {...restProps} disabled={disabled}>
    {icon ? (
      <Box position="absolute" left="-0.5px" display="flex">
        <SVG
          src={icon}
          width="100%"
          height="61px"
          title="src"
          fill={color || '#000000'}
        />
      </Box>
    ) : null}
    <span>{children}</span>
  </StyledButton>
);

export default Button;
