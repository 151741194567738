import React from "react";
import Layout from "./components/Layout";
import Business from "./modules/Business";
import Indie from "./modules/Indie";
import WWS from "./modules/Indie/WWS";
import NotFound from "./modules/NotFound";
import { Routes, Route, Navigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Theme from "./styles/theme/themeRR";

export default function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Layout>
        <Router>
          <Routes>
            <Route index path="/" element={<Navigate to="/business" />}></Route>
            <Route index path="/business" element={<Business />}></Route>
            <Route index path="/indie" element={<Indie />}></Route>
            <Route index path="/indie/wws" element={<WWS />}></Route>
            <Route index path="/wws" element={<Navigate to="/indie/wws" />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </Router>
      </Layout>
    </ThemeProvider>
  );
}
