import { SpaceProps } from 'styled-system';
import { ImgContainer } from './styles';
import React from 'react';

type Props = {
  image: string;
  link: string;
} & SpaceProps;

function CardGame({
  image,
  link,
  ...restProps
}: Props) {
  return (
    <a href={link}>
      <ImgContainer>
        <img src={image} alt={image} />
      </ImgContainer>
    </a>
  );
}

export default CardGame;
