/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  variant, space, SpaceProps, layout, LayoutProps,
} from 'styled-system';

export type StyledProps =
  SpaceProps &
  LayoutProps;

type ButtonProps = {
  fullWidth?: boolean;
  loading?: string;
} & StyledProps;

export const StyledButton = styled.button<ButtonProps>`
  opacity: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 200ms ease;
  cursor: pointer;
  border-style: none;
  background-color: rgba(0,0,0,0);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      svg {
        opacity: 0.7;
        transition: all 200ms ease;
      }
    }
  }

  svg {
    pointer-events:none;
  }

  ${() => variant({
  variants: {
    primary: {
      color: 'black',
      borderColor: 'primary',
      backgroundColor: 'primary',
      svg: {
        fill: 'white',
      },
    },
  },
})}

  ${space};
  ${layout};
`;
