/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';
import {
  space, SpaceProps, fontSize, FontSizeProps,
} from 'styled-system';

export const Fieldset = styled(Grid) <SpaceProps>`
  position: relative;
  ${space};
`;

type InputProps = {
  error?: boolean;
  fullWidth?: boolean;
} & SpaceProps;

export const Input = styled.input<InputProps>`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.manropeRegular};
  min-height: 32px;
  height: auto;
  padding: 8px 12px;
  transition: border-color 150ms ease;
  border-color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.white};
  border-width: 3px;
  border-style: solid;
  border-radius: 16px;
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.black)};

  &::placeholder {
    color: ${(props) => props.theme.colors.darkGray};
    font-family: ${(props) => props.theme.fonts.manropeRegular};
    line-height: 16px;
  }

  width: calc(100% - 30px);
  
  ${space};
`;

interface TextAreaProps {
  error?: boolean;
  placeholder: string;
}

export const TextArea = styled.div<TextAreaProps>`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.manropeRegular};
  min-height: 55.2px;
  height: auto;
  padding: 8px 12px;
  transition: border-color 150ms ease;
  border-color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.white};
  border-width: 3px;
  border-style: solid;
  border-radius: 16px;
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.black)};

  &:empty:before {
    content: "${(props) => props.placeholder}";
    left: 12px;
    color: ${(props) => props.theme.colors.darkGray};
    cursor: text;
  }

  width: calc(100% - 30px);
  
  ${space};
`;

export const Label = styled.label<FontSizeProps>`
  font-size: 16px;
  margin-bottom: 8px;
  margin-left: 8px;
  display: block;
  color: white;
  font-family: ${(props) => props.theme.fonts.manropeSemibold};
  ${fontSize};
  ${space};
`;

export const FieldError = styled.span<SpaceProps>`
  font-size: 14px;
  line-height: 16px;
  padding: 10px 12px;
  display: block;
  font-family: ${(props) => props.theme.fonts.manropeRegular};
  color: ${(props) => props.theme.colors.red};
  ${space};
`;