import styled from 'styled-components';
import TextGlobal from '../../components/Text';
import { LayoutProps, SpaceProps, layout, space, variant } from 'styled-system';

export const Text = styled(TextGlobal)`
   ${variant({
  prop: 'styles',
  variants: {
    title: {
      fontFamily: 'manropeBold',
      color: 'primary',
      margin: '0',
      marginBottom: '24px',

      '@media (min-width: 0px)': {
        fontSize: '60px',
        lineHeight: '60px',
      },

      '@media (min-width: 600px)': {
        fontSize: '80px',
        lineHeight: '80px',
      },

      '@media (min-width: 900px)': {
        fontSize: '100px',
        lineHeight: '100px',
      },
    },
    title2: {
      fontFamily: 'manropeSemibold',
      fontSize: '60px',
      lineHeight: '60px',
      color: 'white',
      margin: '0',

      '@media (min-width: 0px)': {
        fontSize: '50px',
        lineHeight: '50px',
      },

      '@media (min-width: 900px)': {
        fontSize: '60px',
        lineHeight: '60px',
      },
    },
    subtitle: {
      fontFamily: 'manropeBold',
      fontSize: '40px',
      margin: '0',
      marginBottom: '16px',
      lineHeight: '52px',

      '@media (min-width: 0px)': {
        fontSize: '24px',
        lineHeight: '28px',
      },

      '@media (min-width: 900px)': {
        fontSize: '35px',
        lineHeight: '52px',
      },

      '@media (min-width: 1200px)': {
        fontSize: '40px',
        lineHeight: '52px',
      },
    },
    subtitle2: {
      fontFamily: 'manropeSemibold',
      fontSize: '40px',
      margin: '0',
      marginBottom: '16px',
      lineHeight: '52px',

      '@media (min-width: 0px)': {
        fontSize: '30px',
        lineHeight: '32px',
      },

      '@media (min-width: 900px)': {
        fontSize: '40px',
        lineHeight: '52px',
      },
    },
    descWhite: {
      fontFamily: 'manropeRegular',
      fontSize: '18px',
      color: 'white',
      margin: '0',
      textAlign: 'justify',

      '@media (min-width: 0px)': {
        fontSize: '14px',
      },

      '@media (min-width: 900px)': {
        fontSize: '18px',
      },
    },
    descBlack: {
      fontFamily: 'manropeSemibold',
      fontSize: '18px',
      color: 'black',
      margin: '0',
      textAlign: 'justify',

      '@media (min-width: 0px)': {
        fontSize: '14px',
      },

      '@media (min-width: 900px)': {
        fontSize: '18px',
      },

      '& b': {
        fontFamily: 'manropeBold',
      },
    },
    descPink: {
      fontFamily: 'manropeRegular',
      color: 'ultraLightOrange',
      marginTop: '30px',

      '@media (min-width: 0px)': {
        fontSize: '16px',
      },

      '@media (min-width: 900px)': {
        fontSize: '18px',
      },
    },
    footer: {
      fontFamily: 'manropeRegular',
      fontSize: '16px',
      margin: '0',
      marginBottom: '8px'
    },
    servicesDesc: {
      fontFamily: 'manropeSemibold',
      fontSize: '24px',
      margin: '0',
      marginLeft: '24px',

      '@media (min-width: 0px)': {
        fontSize: '18px',
      },

      '@media (min-width: 900px)': {
        fontSize: '24px',
      },
    },
    button: {
      fontFamily: 'manropeRegular',
      fontSize: '18px',
      margin: '0',
      '@media (min-width: 0px)': {
        fontSize: '16px',
      },

      '@media (min-width: 700px)': {
        fontSize: '18px',
      },
    },
    buttonBig: {
      fontFamily: 'manropeRegular',
      fontSize: '18px',
      margin: '0',
      padding: '2px 8px',
      '@media (min-width: 0px)': {
        fontSize: '20px',
      },

      '@media (min-width: 700px)': {
        fontSize: '26px',
      },
    },
    buttonForm: {
      fontFamily: 'manropeSemibold',
      fontSize: '18px',
      margin: '0',
    },
    buttonSmall: {
      fontFamily: 'manropeBold',
      fontSize: '16px',
      margin: '0',
    },
    buttonPortfolio: {
      fontFamily: 'manropeSemibold',
      margin: '0',
      paddingLeft: '70px',

      '@media (min-width: 0px)': {
        fontSize: '14px',
        paddingLeft: '90px',
      },

      '@media (min-width: 600px)': {
        fontSize: '16px',
      },

      '@media (min-width: 900px)': {
        fontSize: '18px',
      },
    },
    trans1TabTitle: {
      color: 'primary',
      backgroundColor: 'black',
      fontFamily: 'manropeBold',
      fontSize: '32px',
      lineHeight: '40px',
      margin: '0px',
      borderRadius: '32px',
      textAlign: 'center',
      width: 'fit-content',
      padding: '10px 24px',
    },
    transDesc: {
      color: 'black',
      fontFamily: 'manropeSemibold',
      '@media (min-width: 0px)': {
        fontSize: '14px',
      },

      '@media (min-width: 900px)': {
        fontSize: '18px',
      },
      margin: '0px',
      textAlign: 'justify',
      '& b': {
        fontFamily: 'manropeBold',
      },
      '& b2': {
        fontFamily: 'manropeSemibold',
        color: 'white',
      },
    },
    personName: {
      fontFamily: 'manropeBold',
      fontSize: '18px',
      color: 'white',
      margin: '0',

      '@media (min-width: 0px)': {
        fontSize: '14px',
      },

      '@media (min-width: 900px)': {
        fontSize: '14px',
      },

      '@media (min-width: 1100px)': {
        fontSize: '18px',
      },
    },
    personJob: {
      fontFamily: 'manropeRegular',
      fontSize: '18px',
      color: 'white',
      margin: '0',

      '@media (min-width: 0px)': {
        fontSize: '14px',
      },

      '@media (min-width: 900px)': {
        fontSize: '14px',
      },

      '@media (min-width: 1100px)': {
        fontSize: '18px',
      },
    },
  },
})}
`;

export const Container = styled.main`
  display: flex-column;
`;

export type StyledProps = SpaceProps & LayoutProps;

interface SectionProps {
  styles: 'black' | 'orange';
}

export const SectionContainer = styled.div<SectionProps & StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  ${variant({
  prop: 'styles',
  variants: {
    black: {
      backgroundColor: 'black',
      color: 'white',
      padding: '0px',
    },
    orange: {
      backgroundColor: 'primary',
      color: 'black',
      padding: '0px',
    },
  },
})}

  ${space};
  ${layout};
`;

export const Section = styled.div<{ width: number }>`
  display: flex-column;
  align-items: center;
  justify-content: center;
  position: relative;

  max-width: 1176px;
  width: 100%;

  margin-left: ${(props) => Math.round(props.width * 180 / 1536)}px;
  margin-right: ${(props) => Math.round(props.width * 180 / 1536)}px;
`;

export const WaveTop = styled.div`
  display: block;
  background-size: 1280px 32px;
  height: 32px;
  position: absolute;
  left: 0px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.black};

  box-shadow: 0 1px 0 ${(props) => props.theme.colors.primary}, 0 -1px 0 ${(props) => props.theme.colors.black};

  bottom: 0px;
  background-image: url('/images/frontpage-wave-top.svg');
  animation: move-horizontal 16s linear infinite;

  @keyframes move-horizontal {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 1280px 0;
    }
  }
`;

export const WaveBottom = styled.div`
  display: block;
  background-size: 1280px 48px;
  height: 48px;
  position: absolute;
  left: 0px;
  width: 100%;

  background-color: ${(props) => props.theme.colors.primary};

  box-shadow: 0 1px 0 ${(props) => props.theme.colors.black}, 0 -1px 0 ${(props) => props.theme.colors.primary};
  
  bottom: 0px;
  background-image: url('/images/frontpage-wave-bottom.svg');
  animation: move-horizontal-reverse 16s linear infinite;

  @keyframes move-horizontal-reverse {
    0% {
      background-position: 1280px 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

export const HorizontalSeparator = styled.div<{ isWhite: boolean }>`
  width: 100px;
  height: 4px;
  position: absolute;
  top: calc(50% - 2px);
  left: calc(58.33333% - 100px);

  background: linear-gradient(to right, ${(props) => props.theme.colors.white} 50%, ${(props) => props.theme.colors.black} 50%);
  transition: background-position 0.3s ease;
  background-size: 200% 100%;
  background-position: ${(props) => (props.isWhite ? "50% 0%" : "-50% 0%")};
`;

export const VerticalSeparator = styled.div<{ isWhite: boolean }>`
  width: 4px;
  height: 40px;
  position: absolute;
  left: calc(50% - 2px);
  bottom: calc(0%);

  background: linear-gradient(to bottom, ${(props) => props.theme.colors.white} 50%, ${(props) => props.theme.colors.black} 50%);
  transition: background-position 0.3s ease;
  background-size: 100% 200%;
  background-position: ${(props) => (props.isWhite ? "0% 50%" : "0% -50%")};
`;

export const PortfolioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${(props) => props.theme.colors.primary};

  @media (min-width: 0px) {
    margin-top: 50px;
    margin-bottom: 10px;
    padding: 25px 35px;
    border-radius: 45px;
  }

  @media (min-width: 900px) {
    margin-top: 90px;
    margin-bottom: 30px;
    padding: 40px 60px;
    border-radius: 80px;

    height: 450px;
  }

  @media (min-width: 1200px) {
    margin-top: 90px;
    margin-bottom: 30px;
    padding: 40px 60px;
    border-radius: 80px;

    height: 350px;
  }
`;

export const NintendoContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  
  & img {
    opacity: 0;
    height: 100%;

    @media (min-width: 0px) {
      max-width: 150%;
      margin-left: -25%;
    }

    @media (min-width: 900px) {
      max-width: 140%;
    }
  }
`;

export const NintendoVideo = styled.div`
  position: absolute;
  height: 100%;

  @media (min-width: 0px) {
    left: -25%;
  }

  @media (min-width: 900px) {
    left: 0%;
  }

  & img{
    opacity: 1;
    margin-left: 0;
  }

  & iframe.yt {
    height: 80%;
    top: 8.5%;

    @media (min-width: 0px) {
      left: 25%;
      width: 70%;
    }

    @media (min-width: 900px) {
      left: 30%;
      width: 80%;
    }
  }
`;

export const PersonContainer = styled.div`
  display: flex-column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  padding: 10px 0px;
  padding-left: 100px;

  @media (min-width: 0px) {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 80px;
  }

  @media (min-width: 600px) {
    margin-left: 40px;
    margin-right: 40px;
  }

  @media (min-width: 750px) {
    margin-left: 80px;
    margin-right: 80px;
  }

  @media (min-width: 900px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (min-width: 1100px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  @media (min-width: 1535px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 80px;
  border-width: 3px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.black};

  img{
    position: absolute;

    @media (min-width: 0px) {
      width: 110px;
      height: 110px;

      left: calc(0% - 30px);
      top: calc(50% - 55px);

      border-radius: 55px;
    }

    @media (min-width: 600px) {
      width: 130px;
      height: 130px;

      left: calc(0% - 24px);
      top: calc(50% - 65px);

      border-radius: 65px;
    }
  }
`;

export const ServicesContainer = styled.div<{ width: number }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: "24px 0px 24px 0px";
  padding-left: ${(props) => Math.round(props.width * 100 / 1536)}px;

  @media (min-width: 0px) {
    margin-bottom: 30px;
  }
`;

export const PartnersContainer = styled.div<{ width: number }>`
  width: calc(100% - ${(props) => Math.round(props.width * 360 / 1536)}px);
  max-width: 1176px;
`;