import styled, { keyframes } from 'styled-components';

const slide = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const LogosSlide = styled.div`
  display: inline-block;
  animation: 20s ${slide} infinite linear;

  img {
    @media (min-width: 0px) {
      height: 70px;
      margin: 0 23px;
    }

    @media (min-width: 700px) {
      height: 100px;
      margin: 0 33px;
    }
  }
`;

export const Logos = styled.div`
  overflow: hidden;
  padding: 20px 0;
  white-space: nowrap;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
`;