import React, { RefObject, useEffect, useRef, useState } from 'react';
import { themeRR } from '../../styles/theme'
import { Grid } from '@mui/material';
import { getSocialsData, getServicesData, getPartnersDetails, getPortfolio, getTransTabData, getUsData } from './data';
import {
  Text, Container, SectionContainer, WaveTop, WaveBottom, HorizontalSeparator, VerticalSeparator,
  PortfolioContainer, NintendoContainer, NintendoVideo, PersonContainer,
  ServicesContainer, PartnersContainer, Section,
} from './styles';
import Box from '../../components/Box';
import Topbar from '../../components/Business/BusinessTopBar';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import { FieldError, Fieldset, Input, Label, TextArea, } from '../../components/Form';
import SVG from 'react-inlinesvg';
import Iframe from 'react-iframe';
import { useIsVisible } from '../../hooks/useIsVisible';
import parse from 'html-react-parser';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import ImgCarousel from '../../components/ImgCarousel';
import emailjs from "@emailjs/browser";
import ReCAPTCHA from 'react-google-recaptcha';

const initialForm = {
  names: '',
  email: '',
  company: '',
  phone: '',
  message: '',
};

const schema = yup.object().shape({
  names: yup
    .string()
    .required('Un nombre es requerido')
    .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre correcto "),
  email: yup
    .string()
    .required('Un correo es requerido')
    .email('Ingrese un correo correcto'),
  company: yup
    .string(),
  phone: yup
    .string()
    .required('Un número de celular es requerido')
    .matches(/^[0-9]+$/, 'Ingrese un número correcto')
    .min(9, 'Ingrese un número correcto')
    .max(9, 'Ingrese un número correcto'),
  message: yup
    .string()
    .required('Un mensaje es requerido'),
});

function Business(): React.ReactNode {

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const lg = useMediaQuery(theme.breakpoints.down('lg'));

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [gameSelected, setGameSelected] = useState<number>(0);
  const [transTabSelected, setTransTabSelected] = useState<number>(0);
  const [trans1Selected, setTrans1Selected] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [captchaMessage, setCaptchaMessage] = useState<string>("");

  const refJuegos: RefObject<HTMLDivElement> = useRef(null);
  const refServicios: RefObject<HTMLDivElement> = useRef(null);
  const refNosotros: RefObject<HTMLDivElement> = useRef(null);
  const refPortafolio: RefObject<HTMLDivElement> = useRef(null);
  const refClientes: RefObject<HTMLDivElement> = useRef(null);
  const refContacto: RefObject<HTMLDivElement> = useRef(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const isJuegosVisible = useIsVisible(refJuegos);
  const isServiciosVisible = useIsVisible(refServicios);
  const isNosotrosVisible = useIsVisible(refNosotros);
  const isPortafolioVisible = useIsVisible(refPortafolio);
  const isClientesVisible = useIsVisible(refClientes);
  const isContactoVisible = useIsVisible(refContacto);

  const socialsData = getSocialsData();
  const servicesData = getServicesData();
  const partnersDetails = getPartnersDetails();
  const portfolio = getPortfolio();
  const transTabData = getTransTabData();
  const usData = getUsData();

  const topbarHeight = sm ? 60 : 80;
  const paddingYSection = 90;

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialForm,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  function scrollToSection(sectionId: string, offset: number = 0) {
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetTop = section.offsetTop - offset;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  }

  function redirectTo(url: string) {
    window.open(url);
  }

  function changeGameSelected(id: number) {
    setGameSelected(id);
  }

  function changeTransTabSelected(id: number) {
    setTransTabSelected(id);
  }

  function changeTrans1Selected(id: number) {
    setTrans1Selected(id);
  }

  const handleChange = (e: any) => {
    setValue('message', e.target.innerHTML);
    clearErrors('message');
  };

  const onSubmitForm = (data: any): void => {

    const recaptchaValue = !!recaptchaRef.current && recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      setCaptchaMessage("El captcha es requerido");
      return;
    }

    setIsLoading(true);

    const params = {
      ...data,
      'g-recaptcha-response': recaptchaValue,
    };

    emailjs.send(
      "service_ifkaspo",
      "template_ik3jew4",
      params,
      "oPgH-MPi0r99sxBsg",
    )
      .then(
        (result) => {
          console.log(result.text);

          reset();

          const divContentEditable = document.getElementById('message');
          if (divContentEditable) {
            divContentEditable.innerHTML = '';
          }

          setCaptchaMessage("");

          setIsLoading(false);
        },
        (error) => {
          console.log(error.text);

          setIsLoading(false);
        }
      )
  };

  useEffect(() => {
    const update = setInterval(() => {

      setScreenWidth(window.innerWidth);

    }, 100);
    return () => clearInterval(update);
  }, [screenWidth]);

  return (
    <Container>
      <Topbar
        isJuegosVisible={isJuegosVisible}
        isServiciosVisible={isServiciosVisible && !isJuegosVisible}
        isNosotrosVisible={isNosotrosVisible && !isServiciosVisible}
        isPortafolioVisible={isPortafolioVisible && !isNosotrosVisible}
        isClientesVisible={isClientesVisible && !isPortafolioVisible}
        isContactoVisible={isContactoVisible && !isClientesVisible}
        width={screenWidth}
      />

      <SectionContainer
        styles="black"
        paddingTop={paddingYSection}
        paddingBottom={`calc(${paddingYSection}px + 16px)`}>

        <Section width={screenWidth}>
          <Grid container spacing={0}>
            {md && (
              <Grid item xs={12} marginBottom="32px">
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                  <SVG
                    src="images/icons/ZANAHORIA.svg"
                    width="100%"
                    height="200px"
                    title="Logo"
                    fill={themeRR.colors.primary}
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={12} md={7}>
              <Box height="100%" display="flex" alignItems="center" justifyContent={md ? "center" : "end"}
                marginRight={(100 * screenWidth / 1535).toString() + "px"}>
                <Box display="flex-column" maxWidth="360px" justifyContent="center">
                  <Text styles="title">
                    ¡HOLA!
                  </Text>

                  <Text styles="title2">
                    Somos<br />rebel rabbit
                  </Text>
                  <Text styles="descPink">
                    Utilizamos videojuegos para proporcionar soluciones a problemas cotidianos de forma digital, interactiva, dinámica y entretenida.
                  </Text>

                  <Button
                    variant='inverse'
                    marginTop="10px"
                    onClick={() => scrollToSection('Servicios', topbarHeight)}>
                    <Text styles="button">
                      Nuestros Servicios
                    </Text>
                  </Button>
                </Box>
              </Box>
            </Grid>

            {!md && (
              <Grid item xs={0} md={5}>
                <Box height="100%" display="flex" alignItems="center" justifyContent={md ? "center" : "start"}
                  marginRight={(100 * screenWidth / 1535).toString() + "px"}>
                  <SVG
                    src="images/icons/ZANAHORIA.svg"
                    width="100%"
                    height="400px"
                    title="Logo"
                    fill={themeRR.colors.primary}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Section>

        <WaveTop />
      </SectionContainer>

      <SectionContainer
        styles="orange"
        paddingTop={`calc(${paddingYSection}px - 16px)`}
        paddingBottom={`calc(${paddingYSection}px + 24px)`}
        id="Juegos" >
        <Section width={screenWidth}>
          <Grid container spacing={0}>

            <Grid item xs={12} marginBottom="40px">
              <Grid container spacing={0} position="relative">
                <Grid item xs={12} md={7} display="flex" alignItems="center" justifyContent={md ? "center" : "end"} ref={refJuegos} position="relative">
                  <Button
                    disabled={transTabSelected === 0}
                    variant='inverse'
                    marginRight={md ? "0px" : "100px"}
                    marginBottom={md ? "40px" : "0px"}
                    width="100%"
                    maxWidth="360px"
                    onClick={() => changeTransTabSelected(0)}>
                    <Text styles="button">
                      TRANSFORMANDO LOS VIDEOJUEGOS
                    </Text>
                  </Button>

                  {md && (
                    <VerticalSeparator isWhite={transTabSelected === 0} />
                  )}
                </Grid>

                {!md && (
                  <HorizontalSeparator isWhite={transTabSelected === 0} />
                )}

                <Grid item xs={12} md={5} display="flex" alignItems="center" justifyContent={md ? "center" : "start"}>
                  <Button
                    disabled={transTabSelected === 1}
                    variant='inverse'
                    width="100%"
                    maxWidth="360px"
                    onClick={() => changeTransTabSelected(1)}>
                    <Text styles="button">
                      TODO PUEDE SER UN VIDEOJUEGO
                    </Text>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {transTabSelected === 0 ? (
              <>
                <Grid item xs={12} md={7} paddingRight={md ? "0px" : "100px"}>
                  <Text styles="subtitle">{transTabData[transTabSelected].title}</Text>

                  {transTabData[transTabSelected].desc.map((desc: any, index: number) => (
                    <>
                      <Text styles="transDesc">{parse(desc)}</Text>
                      <br />
                    </>
                  ))}

                </Grid>

                <Grid item xs={12} md={5}>
                  <Box display="flex-column" >

                    <Box paddingBottom="40px">
                      {transTabData[transTabSelected].tabs.map((tab: any, index: number) => (
                        <Button
                          disabled={trans1Selected === index}
                          variant='white2'
                          marginRight="12px"
                          marginBottom="12px"
                          onClick={() => changeTrans1Selected(tab.id)}>
                          <Text styles="buttonSmall">
                            {tab.name}
                          </Text>
                        </Button>
                      ))}
                    </Box>

                    <Box height="85px" marginBottom="10px">
                      <SVG
                        src={transTabData[transTabSelected].tabs[trans1Selected].icon}
                        width="85px"
                        height="85px"
                        title="Trans1TabSVG"
                        fill={themeRR.colors.black}
                      />
                    </Box>

                    <Text styles="trans1TabTitle">
                      {transTabData[transTabSelected].tabs[trans1Selected].name}
                    </Text>

                    <Box paddingLeft={sm ? "0px" : "24px"} paddingTop="24px">
                      <Text styles="transDesc">
                        {parse(transTabData[transTabSelected].tabs[trans1Selected].desc)}
                      </Text>
                    </Box>

                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={7}>
                  <Text styles="subtitle">{transTabData[transTabSelected].title}</Text>
                </Grid>

                <Grid item xs={0} md={5} />

                <Grid item xs={12} md={7} paddingRight={md ? "0px" : "100px"}>
                  {transTabData[transTabSelected].desc.slice(0, 5).map((desc: any, index: number) => (
                    <>
                      <Text styles="transDesc">{parse(desc)}</Text>
                      <br />
                    </>
                  ))}
                </Grid>

                <Grid item xs={12} md={5} >
                  {transTabData[transTabSelected].desc.slice(5).map((desc: any, index: number) => (
                    <>
                      <Text styles="transDesc">{parse(desc)}</Text>
                      <br />
                    </>
                  ))}
                </Grid>
              </>
            )}

          </Grid>

        </Section>

        <WaveBottom />
      </SectionContainer>

      <SectionContainer
        styles="black"
        paddingTop={`calc(${paddingYSection}px - 24px)`}
        paddingBottom={`calc(${paddingYSection}px + 16px)`}
        id="Servicios" >

        <Section width={screenWidth}>
          <Text styles="subtitle" ref={refServicios}>SERVICIOS</Text>
          <Text styles="descWhite">Somos una empresa profesional dedicada al desarrollo de <b>videojuegos.</b> En <b>rebel rabbit</b>, nos especializamos en la creación de videojuegos <b>2D</b> de cualquier género y estilo.</Text>
          <Text styles="descWhite" marginBottom="32px!important"><br />Puedes usar nuestros videojuegos para:</Text>

          <Grid container spacing={3}>
            {servicesData.map((service: any, index: number) => (
              <Grid item xs={12} md={6} key={index}>
                <ServicesContainer width={screenWidth}>
                  <Box width="40px">
                    <SVG
                      src={service.iconSrc}
                      width="40px"
                      height="40px"
                      title={`logoFooter-${index}`}
                      fill={themeRR.colors.primary}
                    />
                  </Box>
                  <Text styles="servicesDesc">{service.text}</Text>
                </ServicesContainer>
              </Grid>
            ))}
          </Grid>
        </Section>
        <WaveTop />
      </SectionContainer>

      <SectionContainer
        styles="orange"
        paddingTop={`calc(${paddingYSection}px - 16px)`}
        paddingBottom={`calc(${paddingYSection}px + 24px)`}
        id="Nosotros" >

        <Section width={screenWidth}>
          <Text styles="subtitle" ref={refNosotros}>NUESTRO TEAM</Text>
          <Text styles="descBlack">Con más de <b>7 años de experiencia en la industria del desarrollo de videojuegos</b>, en <b>rebel rabbit</b> hemos alcanzado notables logros, incluyendo el primer lugar en el <b>"Concurso Nacional de Videojuegos 2022"</b> organizado por el Ministerio de Cultura del Perú y dos nominaciones como <b>"Mejor Videojuego Latinoamericano"</b> en el prestigioso Big Festival de Brasil en 2017 y 2021. Nuestra pasión por la creatividad y la innovación se refleja en nuestros juegos, lanzados con éxito en plataformas como <b>Steam y Nintendo Switch</b>.</Text>

          <Box margin="80px 0px">
            <Grid container columnSpacing={!lg ? 8 : !md ? 8 : 0} rowSpacing={12}>
              {usData.map((person: any, index: number) => (
                <Grid item xs={12} md={6} xl={4} key={index}>
                  <PersonContainer>

                    <img src={person.iconSrc} alt={`PersonPhoto-${index}`} />

                    <Text styles="personName">{person.name}</Text>
                    <Text styles="personJob">{person.job}</Text>
                  </PersonContainer>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Section>

        <WaveBottom />
      </SectionContainer>

      <SectionContainer
        styles="black"
        paddingTop={`calc(${paddingYSection}px - 24px)`}
        paddingBottom={`calc(${paddingYSection}px + 16px)`}
        id="Portafolio" >

        <Section width={screenWidth}>
          <Text styles="subtitle" ref={refPortafolio}>PORTAFOLIO</Text>

          <Grid container spacing={sm ? 3 : 6} paddingTop="24px">
            {portfolio.map((game: any, index: number) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Button
                  disabled={gameSelected === index}
                  icon={game.icon}
                  height="66px"
                  width="100%"
                  variant='white'
                  onClick={() => changeGameSelected(index)}>
                  <Text styles="buttonPortfolio">
                    {game.name}
                  </Text>
                </Button>
              </Grid>
            ))}
          </Grid>

          <PortfolioContainer>
            <Grid container spacing={sm ? 3 : 1}>
              <Grid item xs={12} md={6} minHeight="220px">
                <Text styles="subtitle2">
                  {portfolio[gameSelected].name}
                </Text>
                <Text styles="descBlack">
                  {parse(portfolio[gameSelected].desc)}</Text>
                <Text styles="descBlack">
                  <br />
                </Text>
                <Text styles="descBlack">
                  {parse(portfolio[gameSelected].etc)}</Text>
              </Grid>

              <Grid item xs={12} md={6} display="flex" alignItems="center">
                <NintendoContainer>
                  <img src={portfolio[gameSelected].urlPlaceholder} alt="nintendo" />
                  <NintendoVideo>
                    <img src={portfolio[gameSelected].urlPlaceholder} alt="nintendoVideo" />
                    <Iframe url={portfolio[gameSelected].url}
                      frameBorder={0}
                      id=""
                      className="yt"
                      display="block"
                      position="absolute"
                    />
                  </NintendoVideo>
                </NintendoContainer>
              </Grid>
            </Grid>
          </PortfolioContainer>

          <Box display="flex" width="100%" alignItems="center" justifyContent="center">
            <Button
              variant='inverse'
              marginTop="40px"
              marginBottom="10px"
              onClick={() => redirectTo("https://rblrbt.com/indie")}>
              <Text styles="buttonBig">
                Nuestros Videojuegos
              </Text>
            </Button>
          </Box>

        </Section>
        <WaveTop />
      </SectionContainer>

      <SectionContainer
        styles="orange"
        paddingTop={`calc(${paddingYSection}px - 16px)`}
        paddingBottom={`calc(${paddingYSection}px + 24px)`}
        id="Clientes" >

        <PartnersContainer width={screenWidth}>
          <Text styles="subtitle" ref={refClientes} marginBottom="60px!important">PARTNERS & CLIENTES</Text>

          <ImgCarousel>
            {partnersDetails.map((partner: any, index: number) => (
              <img src={partner} alt={("partner") + index} />
            ))}
          </ImgCarousel>
        </PartnersContainer>


        <WaveBottom />
      </SectionContainer>

      <SectionContainer
        styles="black"
        paddingTop={`calc(${paddingYSection}px - 24px)`}
        paddingBottom={`calc(${paddingYSection}px + 16px)`}
        id="Contacto" >

        <Section width={screenWidth}>
          <Text styles="subtitle" ref={refContacto}>¿NECESITAS INFORMACIÓN?</Text>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container rowSpacing={3} columnSpacing={4}>
              <Fieldset item xs={12} md={6}>
                <Label htmlFor="nombres">Nombre y Apellido</Label>
                <Input
                  type="text"
                  id="names"
                  placeholder="Ingresa tu nombre"
                  error={!!errors.names}
                  {...register('names')}
                  onChange={(e) => {
                    clearErrors('names');
                    setValue('names', e.target.value);
                  }}
                />
                {!!errors.names && (
                  <FieldError>{errors.names.message}</FieldError>
                )}
              </Fieldset>

              <Fieldset item xs={12} md={6}>
                <Label htmlFor="email">E-mail</Label>
                <Input
                  type="text"
                  id="email"
                  placeholder="Ingresa tu e-mail"
                  error={!!errors.email}
                  {...register("email")}
                  onChange={(e) => {
                    clearErrors('email');
                    setValue('email', e.target.value);
                  }}
                />
                {!!errors.email && (
                  <FieldError>{errors.email.message}</FieldError>
                )}
              </Fieldset>

              <Fieldset item xs={12} md={6}>
                <Label htmlFor="company">Compañía</Label>
                <Input
                  type="text"
                  id="company"
                  placeholder="Ingresa tu compañía"
                  error={!!errors.company}
                  {...register('company')}
                  onChange={(e) => {
                    clearErrors('company');
                    setValue('company', e.target.value);
                  }}
                />
                {!!errors.company && (
                  <FieldError>{errors.company.message}</FieldError>
                )}
              </Fieldset>

              <Fieldset item xs={12} md={6}>
                <Label htmlFor="phone">Celular</Label>
                <Input
                  type="text"
                  id="phone"
                  placeholder="Ingresa tu celular"
                  error={!!errors.phone}
                  {...register('phone')}
                  onChange={(e) => {
                    clearErrors('phone');
                    setValue('phone', e.target.value);
                  }}
                />
                {!!errors.phone && (
                  <FieldError>{errors.phone.message}</FieldError>
                )}
              </Fieldset>

              <Fieldset item xs={12} >
                <Label htmlFor="message">Mensaje</Label>
                <TextArea
                  id="message"
                  contentEditable={true}
                  placeholder="Ingresa tu mensaje"
                  onInput={handleChange}
                  error={!!errors.message}
                  {...register("message")}
                />
                {!!errors.message && (
                  <FieldError>{errors.message.message}</FieldError>
                )}
              </Fieldset>

              <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" marginTop="16px">
                <ReCAPTCHA
                  sitekey="6Lc11REpAAAAAM5u4N0y4zohLSLbtXzEM1EUW1GH"
                  ref={recaptchaRef}
                  theme='dark'
                  onExpired={() => setCaptchaMessage("El captcha expiró")}
                  onChange={() => setCaptchaMessage(!!recaptchaRef.current?.getValue() ? "" : "El captcha es requerido")}
                />
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" paddingTop="0px!important">
                <FieldError>{captchaMessage}</FieldError>
              </Grid>

              <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" marginTop="16px">
                <Button
                  type='submit'
                  variant='primary'
                  width="180px"
                  loading={isLoading}
                  onClick={() => { setCaptchaMessage(!!recaptchaRef.current?.getValue() ? "" : "El captcha es requerido") }}
                >
                  <Text styles="buttonForm">
                    Enviar
                  </Text>
                </Button>
              </Grid>
            </Grid>

          </form>
        </Section>
        <WaveTop />
      </SectionContainer>

      <SectionContainer
        styles="orange"
        paddingTop={`calc(${paddingYSection}px - 16px)`}
        paddingBottom={paddingYSection} >

        <Section width={screenWidth}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Box height="100%" display="flex-column" alignItems="center" justifyContent="center">
                <SVG
                  src="images/icons/REBELRABBIT-FOOTER.svg"
                  width="120px"
                  height="120px"
                  title="logoFooter"
                  fill={themeRR.colors.black}
                />
                <Text styles="footer" marginTop="16px!important">hola@rblrbt.com</Text>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" justifyContent={sm ? "center" : "end"} alignItems="end">
                {socialsData.map((social: any) => (
                  <IconButton
                    icon={social.iconSrc}
                    onClick={() => redirectTo(social.url)}
                    margin="0px 12px"
                    padding="0px"
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Section>

      </SectionContainer>
    </Container>
  );
}

export default Business;
